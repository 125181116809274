var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quotations__container" },
    [
      _c("header", { staticClass: "list-header" }, [
        _c("h2", { staticClass: "list-header__heading" }, [
          _vm._v("Active Requests"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list-header__action-container" },
          [
            _c("fs-button-main", { attrs: { to: { name: "quotationForm" } } }, [
              _vm._v("New request"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.requests, function (request) {
        return _c(
          "router-link",
          {
            key: request.id,
            staticClass: "request",
            attrs: {
              tag: "div",
              to: { name: "quotations-request", params: { id: request.id } },
              exact: "",
            },
          },
          [
            _c("header", { staticClass: "request__header" }, [
              _c("div", { staticClass: "request__heading-container" }, [
                _c("h3", { staticClass: "request__heading" }, [
                  _vm._v(_vm._s(request.productName)),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "request__date" }, [
                _vm._v(
                  _vm._s(_vm.dateFormatter(request.createdAt, "yyyy-MM-dd"))
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "request__status-icons" }, [
                _c("span", {
                  staticClass: "request__status-icon",
                  class: {
                    "request__status-icon--active": request.currentStage == 1,
                  },
                  domProps: { innerHTML: _vm._s(_vm.svg.lightbulb) },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "request__status-icon",
                  class: {
                    "request__status-icon--active": request.currentStage == 2,
                  },
                  domProps: { innerHTML: _vm._s(_vm.svg.language) },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "request__status-icon",
                  class: {
                    "request__status-icon--active": request.currentStage == 3,
                  },
                  domProps: { innerHTML: _vm._s(_vm.svg.assignment) },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "request__status-icon",
                  class: {
                    "request__status-icon--active": request.currentStage == 4,
                  },
                  domProps: { innerHTML: _vm._s(_vm.svg.search) },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "request__status-icon request__status-icon--smaller",
                  class: {
                    "request__status-icon--active": request.currentStage == 5,
                  },
                  domProps: { innerHTML: _vm._s(_vm.svg.thumbsUpDown) },
                }),
              ]),
              _vm._v(" "),
              _c("strong", { staticClass: "request__status" }, [
                _vm._v(
                  _vm._s(_vm.$store.quotations.stages[request.currentStage])
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "request__content" }, [
              _c("div", { staticClass: "request__image-container" }, [
                _c("img", {
                  staticClass: "request__image",
                  attrs: { src: _vm.$blobUrl + "/" + request.productPicture },
                }),
              ]),
              _vm._v(" "),
              _c("dl", { staticClass: "request__details" }, [
                _c("div", { staticClass: "request__field" }, [
                  _c("dt", { staticClass: "request__field-label" }, [
                    _vm._v("Target FOB"),
                  ]),
                  _vm._v(" "),
                  _c("dd", { staticClass: "request__field-value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          request.targetPrice && request.currency
                            ? _vm.currencyFormatter(
                                request.targetPrice,
                                request.currency.code
                              )
                            : request.qiTargetPrice || "-"
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "request__field" }, [
                  _c("dt", { staticClass: "request__field-label" }, [
                    _vm._v("Size range"),
                  ]),
                  _vm._v(" "),
                  _c("dd", { staticClass: "request__field-value" }, [
                    _vm._v(
                      _vm._s(request.qiSizeRangeFrom) +
                        "-" +
                        _vm._s(request.qiSizeRangeTo)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "request__field" }, [
                  _c("dt", { staticClass: "request__field-label" }, [
                    _vm._v("Quantity"),
                  ]),
                  _vm._v(" "),
                  _c("dd", { staticClass: "request__field-value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          (request.totalQuantity
                            ? _vm.numberFormatter(request.totalQuantity)
                            : "") || request.qiTotalQuantity
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "request__field request__field--optional" },
                  [
                    _c("dt", { staticClass: "request__field-label" }, [
                      _vm._v("Status"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      {
                        staticClass: "request__field-value",
                        attrs: {
                          title: request.logEntry
                            ? request.logEntry.content
                            : "",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              request.logEntry ? request.logEntry.content : "-"
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              request.quotations.length
                ? _c(
                    "div",
                    { staticClass: "request__quotations" },
                    [
                      _c("div", { staticClass: "request__quotations-header" }, [
                        _vm._v(
                          "Top Quotations (" +
                            _vm._s(request.quotations.length) +
                            ")"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "request__quotations-header" }, [
                        _vm._v("Price"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "request__quotations-header" }, [
                        _vm._v("MOQ"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "request__quotations-header" }, [
                        _vm._v("Lead Time"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "request__quotations-header request__quotations-header--optional",
                        },
                        [_vm._v("Reference Brands")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.topQuotations(request), function (quotation) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "request__quotations-value",
                              attrs: { title: (quotation.company || {}).name },
                            },
                            [
                              _c("span", {
                                staticClass: "request__quotations-value-icon",
                                domProps: {
                                  innerHTML: _vm._s(_vm.svg.assignment),
                                },
                              }),
                              _vm._v(
                                _vm._s((quotation.company || {}).name) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "request__quotations-value" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.currencyFormatter(
                                      quotation.priceFOBFCA,
                                      (quotation.currency || {}).code
                                    ) || quotation.priceFOBFCA
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "request__quotations-value" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.numberFormatter(quotation.moq) ||
                                    quotation.moq
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "request__quotations-value" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    ("" + quotation.productionLT).match(
                                      /[a-z]/i
                                    )
                                      ? quotation.productionLT
                                      : quotation.productionLT + " days"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "request__quotations-value request__quotations-value--optional",
                              attrs: { title: quotation.otherBrandsProducing },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(quotation.otherBrandsProducing) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("div", { staticClass: "request__no-quotations" }, [
                    _vm._v(
                      "Top quotations will show up here as soon as they are submitted"
                    ),
                  ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "request__buttons" },
                [
                  _c(
                    "fs-button-main",
                    {
                      staticClass: "request__button request__button--optional",
                      attrs: {
                        to: {
                          name: "quotations-request",
                          params: { id: request.id },
                          hash: "#quotations-header",
                        },
                        styling: "text",
                        color: "dark",
                        exact: "",
                      },
                    },
                    [_vm._v("Quotation overview")]
                  ),
                  _c(
                    "fs-button-main",
                    {
                      staticClass: "request__button",
                      attrs: {
                        to: {
                          name: "quotations-request",
                          params: { id: request.id },
                        },
                        styling: "text",
                        color: "dark",
                        exact: "",
                      },
                    },
                    [_vm._v("Product details")]
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      this.ie
        ? _c(
            "quotations/request/index.vue",
            { tag: "component" },
            [
              _vm._v(
                "\n    .quotations .request__details, .quotations .request__quotations { max-width: 61.5rem; }\n\n    "
              ),
              _vm._l(8, function (i) {
                return [
                  _vm._v(
                    "\n      @media (max-width: " +
                      _vm._s(1342 - (i - 1) * 50) +
                      "px) { .quotations .request__details, .quotations\n      .request__quotations { max-width: " +
                      _vm._s(984 - i * 50) +
                      "px; } }\n    "
                  ),
                ]
              }),
              _vm._v(
                "\n\n    .quotations .request__quotations-value--optional { white-space: normal; height: 1.2rem; }\n  "
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }