<template>
  <div class="quotations__container">
    <header class="list-header">
      <h2 class="list-header__heading">Active Requests</h2>
      <!-- FIXME: When smaller than $phone-xs-width screen width,
      change filters-container to dropdown instead, preferably
      with icon to fit on really small screens such as 320px -->
      <div class="list-header__action-container">
        <!--<a class="list-header__filter link link--dark" :class="{'list-header__filter--active': filter.mine }" href="#" @click.prevent="filter.mine = true">Mine</a>
        <a class="list-header__filter link link--dark" :class="{'list-header__filter--active': !filter.mine }" href="#" @click.prevent="filter.mine = false">Company</a>-->
        <fs-button-main :to="{ name: 'quotationForm' }">New request</fs-button-main>
      </div>
    </header>
    <router-link
      tag="div"
      :to="{ name: 'quotations-request', params: { id: request.id } }"
      class="request"
      v-for="request in requests"
      :key="request.id"
      exact
    >
      <header class="request__header">
        <div class="request__heading-container">
          <h3 class="request__heading">{{ request.productName }}</h3>
          <!-- TODO: FIXME -->
          <!-- <span class="request__label">Time to select</span> -->
        </div>
        <span class="request__date">{{ dateFormatter(request.createdAt, 'yyyy-MM-dd') }}</span>
        <div class="request__status-icons">
          <span
            class="request__status-icon"
            :class="{ 'request__status-icon--active': request.currentStage == 1 }"
            v-html="svg.lightbulb"
          ></span>
          <span
            class="request__status-icon"
            :class="{ 'request__status-icon--active': request.currentStage == 2 }"
            v-html="svg.language"
          ></span>
          <span
            class="request__status-icon"
            :class="{ 'request__status-icon--active': request.currentStage == 3 }"
            v-html="svg.assignment"
          ></span>
          <span
            class="request__status-icon"
            :class="{ 'request__status-icon--active': request.currentStage == 4 }"
            v-html="svg.search"
          ></span>
          <span
            class="request__status-icon request__status-icon--smaller"
            :class="{ 'request__status-icon--active': request.currentStage == 5 }"
            v-html="svg.thumbsUpDown"
          ></span>
        </div>
        <strong class="request__status">{{ $store.quotations.stages[request.currentStage] }}</strong>
      </header>
      <div class="request__content">
        <div class="request__image-container">
          <img class="request__image" :src="`${$blobUrl}/${request.productPicture}`" />
        </div>
        <dl class="request__details">
          <div class="request__field">
            <dt class="request__field-label">Target FOB</dt>
            <dd class="request__field-value">
              {{
                request.targetPrice && request.currency
                  ? currencyFormatter(request.targetPrice, request.currency.code)
                  : request.qiTargetPrice || '-'
              }}
            </dd>
          </div>
          <div class="request__field">
            <dt class="request__field-label">Size range</dt>
            <dd class="request__field-value">{{ request.qiSizeRangeFrom }}-{{ request.qiSizeRangeTo }}</dd>
          </div>
          <div class="request__field">
            <dt class="request__field-label">Quantity</dt>
            <dd class="request__field-value">
              {{ (request.totalQuantity ? numberFormatter(request.totalQuantity) : '') || request.qiTotalQuantity }}
            </dd>
          </div>
          <div class="request__field request__field--optional">
            <dt class="request__field-label">Status</dt>
            <dd class="request__field-value" :title="request.logEntry ? request.logEntry.content : ''">
              {{ request.logEntry ? request.logEntry.content : '-' }}
            </dd>
          </div>
        </dl>
        <div class="request__quotations" v-if="request.quotations.length">
          <div class="request__quotations-header">Top Quotations ({{ request.quotations.length }})</div>
          <div class="request__quotations-header">Price</div>
          <div class="request__quotations-header">MOQ</div>
          <div class="request__quotations-header">Lead&nbsp;Time</div>
          <div class="request__quotations-header request__quotations-header--optional">Reference Brands</div>

          <template v-for="quotation in topQuotations(request)">
            <div class="request__quotations-value" :title="(quotation.company || {}).name">
              <span class="request__quotations-value-icon" v-html="svg.assignment"></span
              >{{ (quotation.company || {}).name }}
            </div>
            <div class="request__quotations-value">
              {{ currencyFormatter(quotation.priceFOBFCA, (quotation.currency || {}).code) || quotation.priceFOBFCA }}
            </div>
            <div class="request__quotations-value">{{ numberFormatter(quotation.moq) || quotation.moq }}</div>
            <div class="request__quotations-value">
              {{
                ('' + quotation.productionLT).match(/[a-z]/i)
                  ? quotation.productionLT
                  : quotation.productionLT + ' days'
              }}
            </div>
            <div
              class="request__quotations-value request__quotations-value--optional"
              :title="quotation.otherBrandsProducing"
            >
              {{ quotation.otherBrandsProducing }}
            </div>
          </template>
        </div>
        <div class="request__no-quotations" v-else>Top quotations will show up here as soon as they are submitted</div>
        <div class="request__buttons">
          <fs-button-main
            :to="{ name: 'quotations-request', params: { id: request.id }, hash: '#quotations-header' }"
            class="request__button request__button--optional"
            styling="text"
            color="dark"
            exact
            >Quotation overview</fs-button-main
          ><!--
          --><fs-button-main
            :to="{ name: 'quotations-request', params: { id: request.id } }"
            class="request__button"
            styling="text"
            color="dark"
            exact
            >Product details</fs-button-main
          >
        </div>
      </div>
    </router-link>
    <!-- IE11 fixes for overflowing text -->
    <component :is="'quotations/request/index.vue'" v-if="this.ie">
      .quotations .request__details, .quotations .request__quotations { max-width: 61.5rem; }

      <template v-for="i in 8">
        @media (max-width: {{ 1342 - (i - 1) * 50 }}px) { .quotations .request__details, .quotations
        .request__quotations { max-width: {{ 984 - i * 50 }}px; } }
      </template>

      .quotations .request__quotations-value--optional { white-space: normal; height: 1.2rem; }
    </component>
  </div>
</template>

<script>
import checkIfIE from 'apps/public/lib/checkIfIE';
import formatters from 'apps/public/lib/formatters';

import FsButtonMain from '@components/buttons/fs-button-main.vue';

import assignment from 'apps/public/img/assignment-24px.svg';
import language from 'apps/public/img/language-24px.svg';
import lightbulb from 'apps/public/img/lightbulb-outline.svg';
import search from 'apps/public/img/search-24px.svg';
import thumbsUpDown from 'apps/public/img/thumbs_up_down-24px.svg';

export default {
  name: 'quotations',
  load({ store, route }) {
    store.head.title = `FindSourcing – Quotation Requests`;

    return store.quotations.loadRequests({ shallow: true });
  },
  components: { FsButtonMain },
  data() {
    return {
      ie: false,
      svg: {
        assignment,
        language,
        lightbulb,
        search,
        thumbsUpDown,
      },
      filter: {
        mine: false,
      },
    };
  },
  computed: {
    requests() {
      return this.filter.mine ? this.$store.quotations.myShallowRequests : this.$store.quotations.shallowRequests;
    },
  },
  methods: {
    ...formatters,
    topQuotations(request) {
      return (request.quotations || []).slice(0, 2);
    },
  },
  mounted() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}

    this.ie = checkIfIE();
  },
  beforeDestroy() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/media-queries';
@import 'common/styles/text';
@import 'common/styles/variables';

.quotations {
  &__container {
    max-width: 82rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
  }

  .list-header {
    display: flex;
    align-items: flex-end;

    @include mobile-xs-only {
      flex-wrap: wrap;
    }

    &__heading {
      // Based on (very similar) to home page -> category__heading style
      @include heading--small;
      display: inline-block;
      position: relative;
      padding: 0.625rem 0;
      margin: 0 auto 0 2rem;

      @include mobile-only {
        margin: 0 auto 0 1.5rem;
      }

      // Together with below, a lot of small adjustments to make it
      // better centered (due to uneven space before especially
      // capital B). The underline should be 1px before/after each
      // first/last letter;

      // Underline;
      &:after {
        content: '';
        position: absolute;
        left: -0.0625rem;
        right: 0;
        bottom: (0.625rem - 0.25rem);
        height: 2px;
        background-color: $c-dark;
      }
    }

    &__action-container {
      margin: 0 0.75rem 0.125rem 0.75rem;

      @include mobile-only {
        margin-right: 0.5rem;
      }
    }

    &__filter {
      display: inline-block;
      position: relative;
      padding: 0.625rem 0.75rem 0.625rem 0.75rem;
      margin: 0 0.5rem 0 0;
      text-decoration: none;

      @include mobile-only {
        margin: 0 0.25rem 0 0;
      }

      // Together with below, a lot of small adjustments to make it
      // better centered (due to uneven space before especially
      // capital B). The underline should be 1px before/after each
      // first/last letter;

      // Underline;
      &:after {
        content: '';
        position: absolute;
        left: (0.75rem + 0rem);
        right: (0.75rem + -0.0625rem);
        bottom: (0.625rem - 0.375rem);
        height: 2px;
        transition: background-color 0.15s ease-in-out; // Taken from link.scss;
        background-color: transparent;
      }

      &--active,
      &:hover {
        color: $c-blue;

        // Underline;
        &:after {
          background-color: $c-blue;
        }
      }
    }
  }

  .request {
    position: relative;
    padding: 1rem 2rem;
    margin-top: 1.5rem;
    box-shadow: $bs-medium; // Taken from pages/home;
    border-radius: $br-light;
    cursor: pointer;

    @include mobile-only {
      padding: 0.75rem 1.5rem 1rem 1.5rem;

      &:first-of-type {
        margin-top: 1rem;
      }
    }

    &:hover {
      box-shadow: $bs-high-medium;
    }

    // Header
    &__header {
      display: flex;
      margin-top: 0.5rem;
      align-items: center;

      @include mobile-only {
        flex-wrap: wrap;
      }
    }

    &__heading-container {
      margin-right: auto;

      @include mobile-only {
        width: 100%;
        margin-bottom: 0.25rem;
      }
    }

    &__heading {
      @include heading--tiny;
      display: inline-block;
      margin: 0 1rem 0 0;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &__label {
      @include overline;
      display: inline-flex;
      margin: 0 0.25rem;
      padding: 0 0.75rem;
      height: 1.5rem;
      border-radius: $br-light;
      color: $c-light;
      background-color: $c-green;
      align-items: center;

      @include mobile-only {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    &__date {
      @include caption;
      display: inline-block;
      position: relative;
      top: 1px; // To adjust for "playing with the big boys" (adjusting for line-height 14/16px difference);
      margin-right: 1.5rem;

      @include mobile-xs-only {
        top: 0;
      }
    }

    &__status-icons {
      margin-right: 1.25rem;

      @include mobile-to-tablet {
        display: none;
      }
    }

    &__status-icon {
      display: inline-block;
      position: relative;
      top: 0;
      margin: 0 math.div(0.8rem, 1.6);
      fill: $c-dark;
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
      transition: fill 0.15s ease-in-out;
      opacity: 0.47;

      &--smaller {
        svg {
          transform: scale(0.8);
        }
      }

      &--active {
        opacity: 1;
      }
    }

    &__status {
      display: inline-block;
    }

    // Content
    &__content {
      display: grid;
      grid-template-columns: 16.5rem auto;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'image details'
        'image quotations';
      /* autoprefixer: ignore next */
      align-items: start;
      margin: 1.25rem 0 0 0;

      @include mobile-only {
        grid-template-columns: 10.2rem auto;
        grid-template-areas:
          'image details'
          'quotations quotations';
        margin-top: 1.5rem;
      }

      @include mobile-xs-only {
        grid-template-columns: 8.5rem auto;
        grid-template-areas:
          'details details'
          'quotations quotations';
      }
    }

    // Image
    &__image-container {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      height: 11.25rem;
      width: 14.5rem;
      margin: 0 0 1rem 0;
      grid-area: image;

      @include mobile-only {
        height: 6.75rem;
        width: 8.7rem;
      }

      @include mobile-xs-only {
        height: 5.82rem;
        width: 7.5rem;
      }

      @include mobile-xs-only {
        position: absolute;
      }
    }

    &__image {
      max-height: 100%;
      max-width: 100%;
      flex-shrink: 0;
    }

    // Details
    &__details {
      display: flex;
      overflow: hidden;
      margin: 0.625rem 0 0 0;
      grid-area: details;

      @include mobile-only {
        flex-wrap: wrap;
      }

      @include mobile-xs-only {
        margin-top: 0.125rem;
      }
    }

    &__field {
      flex-shrink: 0;
      margin: 0 1rem 0 0;
      overflow: hidden;
      min-width: 6.25rem;

      @include mobile-only {
        flex-shrink: 1;
        flex-grow: 1;
        min-width: 0;
      }

      @include mobile-xs-only {
        margin-bottom: 1rem;

        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(n + 1):nth-child(-n + 2) {
          margin-left: 8.5rem;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &--optional {
        flex-shrink: 1;

        @include mobile-only {
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        @include mobile-xs-only {
          margin-top: 0.25rem;
        }
      }

      &-label {
        @include overline;
      }

      &-value {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mobile-only {
          white-space: normal;
        }
      }
    }

    // Quotations
    &__quotations {
      display: grid;
      column-gap: 2.5rem;
      grid-template-columns:
        minmax(12rem, 6fr) minmax(min-content, 2fr) minmax(min-content, 2fr) minmax(min-content, 2fr)
        auto;
      grid-template-rows: auto auto auto; // IE11 fix;
      margin-top: 1.5625rem;
      grid-area: quotations;

      @include mobile-to-tablet {
        column-gap: 1rem;
        /* autoprefixer: ignore next */
        grid-template-columns: auto minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
      }

      @include mobile-only {
        margin-top: 0.5625rem;
        margin-bottom: 2.25rem;
      }

      @include mobile-xs-only {
        /* autoprefixer: ignore next */
        grid-template-columns: auto minmax(min-content, auto) minmax(min-content, auto);
      }
    }

    &__quotations-header {
      @include overline;
      margin: 0.0625rem 0;

      &--optional {
        @include mobile-to-tablet {
          display: none;
        }
      }

      @include mobile-xs-only {
        &:nth-child(n + 4) {
          display: none;
        }
      }
    }

    &__quotations-value {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0.375rem 0;

      &:nth-child(5n + 1) {
        padding-left: 1.5rem;
      }

      @include mobile-xs-only {
        &:nth-child(5n + 4) {
          display: none;
        }
      }

      &--optional {
        @include mobile-to-tablet {
          display: none;
        }
      }
    }

    &__quotations-value-icon {
      position: absolute;
      top: 0.125rem;
      left: -1px;
      fill: $c-dark;
      width: 1.125rem;
      height: 1.125rem;
    }

    &__no-quotations {
      @include caption;
      justify-self: center;
      align-self: center;
      padding-bottom: 1rem;
      text-align: center;

      @include mobile-only {
        margin-top: 0.5625rem;
        grid-column: 1 / 3;
        margin-bottom: 1.25rem;
      }
    }

    &__buttons {
      position: absolute;
      bottom: 0.8125rem;
      right: 0.6875rem;

      @include mobile-only {
        display: flex;
        bottom: 0.5rem;
        left: 0.1875rem;
        right: 0.1875rem;
        justify-content: flex-end;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__button {
      overflow: hidden;
      text-overflow: ellipsis;

      &--optional {
        @include mobile-xs-only {
          display: none;
        }
      }
    }
  }
}
</style>
